angular.module("UI8.controllers").controller("DownloadCountController", [
  "$scope",
  "$interval",
  "PromoService",
  function ($scope, $interval, PromoService) {
    function initializeCountdown() {
      var DateTime = window.luxon.DateTime;
      var Interval = window.luxon.Interval;

      var end = DateTime.local().setZone("America/Los_Angeles").endOf("day");
      var now = DateTime.local().setZone("America/Los_Angeles");
      var interval = Interval.fromDateTimes(now, end).toDuration("seconds");
      var elapsed = Interval.fromDateTimes(now, end).toDuration("minutes");

      if (!interval.isValid) return;

      $scope.timeUntilReset = interval.toFormat("h:mm:ss");
      $scope.minutesElapsed = Math.floor(elapsed.minutes);

      var intervalPromise = $interval(function () {
        interval = interval.minus({ seconds: 1 });
        if (!interval.isValid) {
          $interval.cancel(intervalPromise);
          return;
        }
        $scope.timeUntilReset = interval.toFormat("h:mm:ss");
        if (Math.floor(interval.seconds) % 60 === 0) {
          elapsed = elapsed.plus({ minutes: 1 });
          $scope.minutesElapsed = Math.floor(elapsed.minutes);
        }
      }, 1000);
    }

    function downloadSuccessListener(event) {
      if (
        !event.detail ||
        !Object.prototype.hasOwnProperty.call(
          event.detail,
          "downloadsRemaining"
        ) ||
        $scope.downloadsRemaining === event.detail.downloadsRemaining
      )
        return;

      $scope.downloadsRemaining = event.detail.downloadsRemaining;
      if ($scope.downloadsRemaining <= 0) {
        initializeCountdown();
      }
    }

    $scope.init = function (remainingCount) {
      $scope.downloadsRemaining = parseInt(remainingCount) || 0;

      document.body.addEventListener(
        "download-success",
        downloadSuccessListener
      );

      if (!$scope.downloadsRemaining && window.luxon) {
        initializeCountdown();
      }
    };

    // 15 means that passrenew15 and passupgrade15 has 15% of discount
    $scope.triggerRenew = function () {
      PromoService.lastOrder().then(function (lastOrder) {
        if (lastOrder.campaign === "aap40") {
          PromoService.applyPromo("passrenew40").then(function (promo) {
            UI8.promo = promo;
            UI8.isRenewal = true;
            var event = new Event("promo-updated");
            document.body.dispatchEvent(event);
          });
        } else {
          if (!UI8.promo || (!!UI8.promo && UI8.promo.discount < 15)) {
            PromoService.applyPromo("passrenew15").then(function (promo) {
              UI8.promo = promo;
              UI8.isRenewal = true;
              var event = new Event("promo-updated");
              document.body.dispatchEvent(event);
            });
          }
        }
      });

      $("body").addClass("pass-pricing-modal");
    };

    $scope.triggerUpgrade = function () {
      PromoService.lastOrder().then(function (lastOrder) {
        if (lastOrder.campaign === "aap40") {
          PromoService.applyPromo("passupgrade40").then(function (promo) {
            UI8.promo = promo;
            UI8.isRenewal = true;
            var event = new Event("promo-updated");
            document.body.dispatchEvent(event);
          });
        } else {
          if (!UI8.promo || (!!UI8.promo && UI8.promo.discount < 15)) {
            PromoService.applyPromo("passupgrade15").then(function (promo) {
              UI8.promo = promo;
              UI8.isRenewal = true;
              var event = new Event("promo-updated");
              document.body.dispatchEvent(event);
            });
          }
        }
      });

      $("body").addClass("pass-pricing-modal");
    };

    $scope.$on("$destroy", function () {
      document.body.removeEventListener(
        "download-success",
        downloadSuccessListener
      );
    });
  },
]);
